import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import "./registerServiceWorker";
import i18n from "./i18n";
import axios from "axios";
import {
  AxiosInterceptorOnFullFilled,
  AxiosInterceptorOnRejected,
} from "./middleware/axios_interceptors";

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import AppLayout from "@/layouts/AppLayout";
import SmallLayout from "@/layouts/SmallLayout";
import SmallWithBackButtonLayout from "@/layouts/SmallWithBackButtonLayout";

store.commit("auth/INIT_AUTH");
store.commit("visits/INIT_AUTH");

// Subscribe to store updates
store.subscribe((mutation, state) => {
  if (mutation.type !== "auth/INIT_AUTH") {
    // Store the state object as a JSON string
    localStorage.setItem("store_auth_details", JSON.stringify(state.auth));
    localStorage.setItem(
      "store_visit_processing",
      JSON.stringify(state.visits.processing)
    );
  }
});

router.beforeEach((to, from, next) => {
  if (!store.getters["auth/loggedin"] && to.name !== "login") {
    next({ name: "login" });
  }
  next();
});

window.addEventListener("message", (e) => {
  if (e.data.action === "skipWaiting") {
    store.dispatch("auth/updateApp");
  }
});

axios.interceptors.response.use(
  AxiosInterceptorOnFullFilled,
  AxiosInterceptorOnRejected
);

const app = createApp(App);

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

app
  .use(store)
  .use(router)
  .use(i18n)
  .component("app-layout", AppLayout)
  .component("small-layout", SmallLayout)
  .component("small-with-back-button-layout", SmallWithBackButtonLayout)
  .mount("#app");
