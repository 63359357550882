<template>
  <small-layout>
    <div class="accepting-active-visit">
      <template v-if="processing">
        <div class="flex-container">
          <h2 class="flex-cell accepting-visit-one-line">
            {{ processing.holder }}
          </h2>
        </div>
        <div class="flex-container">
          <h4
            class="flex-cell accepting-visit-break-word"
            v-if="processing.level"
          >
            {{ $t("vs.level_title") }}
            {{ $t("vs.level_card_" + processing.level) }}
          </h4>
          <h4
            class="flex-cell accepting-visit-break-word"
            v-if="processing.attraction && processing.attraction.id"
          >
            {{ $t("vs.attraction_title") }} {{ processing.attraction.name }}
          </h4>
          <h4 class="flex-cell accepting-visit-break-word">
            {{ $t(":legal_name:") }}
          </h4>
        </div>
        <h2>
          <b>{{ $t("vs.review_id") }}</b>
        </h2>
        <img src="@/assets/img/documents.png" alt="documents" />
        <validation-errors></validation-errors>
        <div class="flex-container flex-2cols">
          <div class="flex-cell">
            <button
              class="button button-warning visit_reject"
              :disabled="uiDisabled"
              @click="rejectVisit"
            >
              {{ $t("av.reject") }}
            </button>
          </div>
          <div class="flex-cell">
            <button
              class="button button-success visit_accept"
              :disabled="uiDisabled"
              @click="acceptVisit"
            >
              {{ $t("av.confirm") }}
            </button>
          </div>
        </div>
      </template>
      <div v-else>
        <router-link :to="`/visits/preview_registration?t=${Date.now()}`">{{
          $t("app.preview_registration")
        }}</router-link>
      </div>
    </div>
  </small-layout>
</template>

<style lang="scss">
.button {
  width: 100%;
  &.button-warning {
    color: $alert-color;
    background-color: $primary-color;
    border-color: $border-alert-color;
  }
  &.button-success {
    color: $info-color;
    background-color: $bg-info-color;
    border-color: $border-info-color;
  }
}

/* Tables
  ================================== */
.flex-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  > .flex-cell {
    box-sizing: border-box;
    flex-grow: 1;
    width: 100%; // Default to full width
    // padding: 0.8em 1.2em;
    overflow: hidden; // Or flex might break
    list-style: none;
    border: solid 3px $primary-color;
    margin: 0;
    padding: 0;
    > h1,
    > h2,
    > h3,
    > h4,
    > h5,
    > h6 {
      margin: 0;
    }
  }
  > .accepting-visit-break-word {
    word-break: break-word;
  }
  > .accepting-visit-one-line {
    text-overflow: clip;
    white-space: nowrap;
  }
}

/* Table column sizing
  ================================== */
.flex-2cols > .flex-cell {
  width: 50%;
}
.flex-3cols > .flex-cell {
  width: 33.33%;
}
.flex-4cols > .flex-cell {
  width: 25%;
}
.flex-5cols > .flex-cell {
  width: 20%;
}
.flex-6cols > .flex-cell {
  width: 16.6%;
}

#reason_text_label {
  width: 100%;
}
</style>

<style lang="scss" scoped>
.accepting-active-visit {
  position: relative;
  > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 25%;
    z-index: -1;
  }
}

@media screen and (max-width: 767px) {
  .accepting-active-visit {
    position: static;
    > img {
      position: static;
      width: 10em;
    }
  }
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import { isActivated } from "@/middleware/filters";
import ValidationErrors from "@/views/ValidationErrors";

export default {
  name: "AcceptingActiveVisit",
  components: { ValidationErrors },
  filters: { isActivated },
  data() {
    return {
      uiDisabled: false,
    };
  },
  computed: {
    ...mapGetters({
      processing: "visits/getActiveVisit",
    }),
  },
  mounted() {
    const amountGeolocationKey = "x-supplier-geolocation-accepting-visit";
    let amount = localStorage.getItem(amountGeolocationKey);
    if (amount++ % 100 === 0 && "geolocation" in window.navigator) {
      window.navigator.geolocation.getCurrentPosition(function (location) {
        this.geolocation(location.coords);
      });
    }
    localStorage.setItem(amountGeolocationKey, amount.toString());
  },
  methods: {
    ...mapActions({
      confirmed: "visits/confirmed",
      rejected: "visits/rejected",
      geolocation: "auth/setGeolocation",
    }),
    async acceptVisit() {
      this.uiDisabled = true;
      const routeName = await this.confirmed();
      this.uiDisabled = false;
      if (this.$route.name !== routeName) {
        this.$router.push({ name: routeName });
      }
    },
    async rejectVisit() {
      this.uiDisabled = true;
      await this.rejected();
      this.uiDisabled = false;
      this.$router.push({ name: "reject_active_visit" });
    },
  },
};
</script>
