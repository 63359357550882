<template>
  <app-layout>
    <div>
      <h1>{{ $t("avi.title") }}</h1>
      <button @click="startRegistration">
        {{ $t("app.new_registration") }}
      </button>
      <div>
        <button @click="todayFilter">{{ $t("avi.today_label") }}</button>
        <button @click="weekFilter">{{ $t("avi.week_label") }}</button>
        <button @click="monthFilter">{{ $t("avi.month_label") }}</button>
        <input
          aria-label="choose month"
          type="month"
          name="month_query"
          v-model="monthKey"
          @change="monthFilter"
        />
      </div>
      <table class="accepted_visits">
        <thead>
          <tr>
            <th colspan="4">{{ $t("avi.total") }}</th>
            <th v-if="is_finance">{{ $t("avi.amount") }}</th>
          </tr>
          <tr>
            <td colspan="4">{{ summary.count }}</td>
            <td v-if="is_finance">
              {{ $n(priceIntToFloat(summary.price), "currency") }}
            </td>
          </tr>
        </thead>
        <thead>
          <tr>
            <th
              v-for="item in columns"
              @click="sortBy(item.key)"
              :class="{
                active:
                  sortKey === item.key &&
                  typeof sortOrders[item.key] !== 'undefined',
              }"
              :key="item.key"
            >
              {{ capitalize(item.name) }}
              <span
                class="arrow"
                :class="sortOrders[item.key] > 0 ? 'asc' : 'dsc'"
              >
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="entry in filteredData" :key="entry.id">
            <td>{{ $d(parseDate(entry.created_at), "long") }}</td>
            <td>{{ $t(":legal_name:") }}</td>
            <td>{{ entry.attraction.name }}</td>
            <td>{{ entry.id }}</td>
            <td v-if="is_finance">
              {{ $n(priceIntToFloat(entry.price), "currency") }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </app-layout>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";
import { parseDate, priceIntToFloat } from "@/helpers";
const today = new Date();
function addZ(n) {
  return (n < 10 ? "0" : "") + n;
}
const date = today.getFullYear() + "-" + addZ(today.getMonth() + 1);
export default {
  data() {
    return {
      sortKey: 0,
      sortOrders: { created_at: 1, id: 0 },
      filterKey: "",
      monthKey: date,
      range: "day",
    };
  },
  computed: {
    ...mapGetters({
      is_finance: "auth/getIsFinancePosition",
      proccessing: "visits/getActiveVisit",
    }),
    columns() {
      let columns = [
        { key: "created_at", name: this.$i18n.t("avi.datetime_label") },
        { key: "aggregator", name: this.$i18n.t("avi.aggregator_label") },
        { key: "attraction", name: this.$i18n.t("avi.attraction_label") },
        { key: "id", name: "N" },
      ];
      if (this.is_finance) {
        columns.push({ key: "price", name: this.$i18n.t("avi.payment_label") });
      }
      return columns;
    },
    ...mapState({
      summary(state) {
        let summary = {
          count: 0,
          attractions: {},
          created_at: new Date().toJSON(),
          price: 0,
        };
        state.visits.accepted_visits.map((visit) => {
          summary.count++;
          summary.attractions[visit.attraction.name] = 1;
          if (summary.created_at > visit.created_at) {
            summary.created_at = visit.created_at;
          }
          if (Number.isInteger(visit.price)) {
            summary.price += visit.price;
          }
        });
        summary.attraction_count = Object.getOwnPropertyNames(
          summary.attractions
        ).length;
        return summary;
      },
      filteredData(state) {
        let sortKey = this.sortKey;
        let filterKey = this.filterKey && this.filterKey.toLowerCase();
        let order = this.sortOrders[sortKey];
        let data = state.visits.accepted_visits;
        if (filterKey) {
          data = data.filter(function (row) {
            return Object.keys(row).some(function (key) {
              return String(row[key]).toLowerCase().indexOf(filterKey) > -1;
            });
          });
        }
        if (sortKey) {
          data = data.slice().sort(function (a, b) {
            a = a[sortKey];
            b = b[sortKey];
            return (a === b ? 0 : a > b ? 1 : -1) * order;
          });
        }
        return data;
      },
    }),
  },
  mounted() {
    this.getAcceptedVisits({
      date_range: "today",
      date: this.monthKey,
    });
  },
  methods: {
    parseDate,
    priceIntToFloat: priceIntToFloat,
    ...mapActions({
      getAcceptedVisits: "visits/getAcceptedVisits",
    }),
    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    sortBy: function (key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1;
    },
    todayFilter() {
      this.monthKey = date;
      this.range = "today";
      this.getAcceptedVisits({
        date_range: "today",
        date: this.monthKey,
      });
    },
    weekFilter() {
      this.monthKey = date;
      this.range = "week";
      this.getAcceptedVisits({
        date_range: "week",
        date: this.monthKey,
      });
    },
    monthFilter() {
      this.range = "month";
      this.getAcceptedVisits({
        date_range: "month",
        date: this.monthKey,
      });
    },
    startRegistration() {
      this.$router.push({
        name: "new_registration",
        query: { t: Date.now().toString() },
      });
    },
  },
};
</script>
