<template>
  <div id="app">
    <div>
      <nav class="attached-nav">
        <router-link to="/accepted/index">{{ $t("app.visits") }}</router-link>
        <a href="#" @click="doLogout">{{ $t("app.quit") }}</a>
      </nav>
      <nav class="attached-nav">
        <a class="text-break" :href="supportPhone" target="_blank">{{
          $t("app.support_title", { tel: supportPhone })
        }}</a>
        <router-link to="/contact_info">{{ supplier_name }}</router-link>
      </nav>
      <transition name="fade">
        <slot></slot>
      </transition>
    </div>
  </div>
</template>

<script>
import packageJson from "../../package";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "SmallLayout",
  data() {
    return {
      version: packageJson.version,
    };
  },
  computed: {
    ...mapGetters({
      supportPhone: "auth/supportPhone",
      current_locale: "auth/locale",
      supplier_name: "auth/getSupplierName",
      errorResponse: "error/getErrorResponse",
      message: "error/getMessage",
    }),
  },
  methods: {
    toggle_dom_visit() {
      if (this.$refs.dom_visit) {
        this.$refs.dom_visit.style.display =
          this.$refs.dom_visit.style.display === "none" ? "" : "none";
      }
    },
    ...mapActions({
      logout: "auth/logout",
    }),
    async doLogout() {
      try {
        await this.logout();
        this.$router.push({ name: "login" });
      } catch (response) {
        this.$router.push({ name: "login" });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.color-primary {
  color: $primary-color;
}
.text-break {
  word-break: break-all;
  width: 45%;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
nav {
  margin: 0;
  padding: 0;
  display: flex;
  overflow: hidden;
  background-color: $black;
  > button,
  > a,
  > span {
    width: 50%;
    font-size: 1em;
    display: inline-block;
    color: white;
    text-align: center;
    padding: 1em 0.5em;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;

    :hover:not(.active) {
      background-color: #111;
    }

    .active {
      background-color: #4caf50;
    }
  }
}

.spinLoading {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  height: 150px;
  width: 250px;
  position: fixed;
  z-index: 1000;
  left: 50%;
  top: 50%;
  margin: -125px 0 0 -125px;
  text-align: center;
  display: none;
  .showLoading {
    display: block;
  }
}
</style>
