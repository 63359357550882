<template>
  <div id="app">
    <div>
      <nav class="attached-nav">
        <a @click="goBack">{{ $t("app.back") }}</a>
        <span class="color-primary"
          ><a class="text-break" :href="support.tel" target="_blank">{{
            $t("app.support_title", { tel: support.tel_title })
          }}</a></span
        >
      </nav>
      <validation-errors
        :response="errorResponse"
        v-if="errorResponse"
      ></validation-errors>
      <transition name="fade">
        <slot></slot>
      </transition>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
nav {
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333;
  > button,
  a {
    font-size: 16px;
    display: block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;

    :hover:not(.active) {
      background-color: #111;
    }

    .active {
      background-color: #4caf50;
    }
  }
}
</style>
<script>
import { mapGetters } from "vuex";
import ValidationErrors from "../views/ValidationErrors";
export default {
  name: "SmallWithBackButtonLayout",
  components: {
    ValidationErrors,
  },
  computed: {
    ...mapGetters({
      support: "auth/support",
      errorResponse: "error/getErrorResponse",
    }),
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
